import { Col } from "antd";
import { Slider } from "antd";
import { modifyScale } from "./Inference";


export const LoraWeightSlider = ({ property, m, setActiveLoras }) => {
    const changeScale = (value) => {
        // Reconstructs the LoRA scale object, but alters the associated property.
        function adjust(lora) {
            return ({
                ...lora,
                scales: modifyScale(lora.scales, value, property)
            });
        }

        setActiveLoras(v => v.map(x => x === m ? adjust(x) : x));
    };

    const capitalized = property.charAt(0) + property.substring(1);

    return (
        <Col span={24} className="lora-weight-slider" onDoubleClick={() => alert('poep in je neus')}>
            <Slider
                tooltip={{ formatter: n => `${capitalized}: ${n}` }}
                min={0}
                max={2}
                step={0.05}
                value={m.scales.unet[property]}
                onChange={changeScale} />
        </Col>
    );
};
