import React, { useState, useEffect } from "react";
import { Button, Checkbox } from "antd/es";

import { putRunArgs, fetchRunArgs, logArgumentModified } from "../api";
import { HIDDEN, OBSOLETE } from "../datasets";

const ArgsEditor = (props) => {
    const { runId, statusRef } = props;
    const [args, setArgs] = useState('{}');
    const [originalArgs, setOriginalArgs] = useState({});
    const [filteredArgs, setFilteredArgs] = useState({});
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const [showHidden, setShowHidden] = useState(true);

    const fetchRun = async (runId) => {
        setLoading(true);

        try {
            const runArgs = await fetchRunArgs(runId);

            const filteredArgs = Object.fromEntries(Object.entries(runArgs).filter(([k, _]) => HIDDEN.includes(k) || OBSOLETE.includes(k)))

            // Sort keys alphabetically
            const sorted = {};
            Object.keys(runArgs)
                .sort()
                .filter(k => !OBSOLETE.includes(k) && (showHidden || !HIDDEN.includes(k)))
                .forEach((k) => (sorted[k] = runArgs[k]));

            setArgs(JSON.stringify(sorted, null, 4));
            setFilteredArgs(filteredArgs);
            setOriginalArgs(runArgs);
        } catch (error) {
            console.error("Error fetching run args:", error);
            setStatus("Error fetching run args: " + error);
        }

        setLoading(false);
    };

    const saveRun = async (runId, newArgs) => {
        setLoading(true);

        try {
            const objNew = JSON.parse(newArgs);

            if (objNew) {
                const obj = { ...filteredArgs, ...objNew };

                const step = statusRef?.current?.current_status?.step ?? -1;

                await putRunArgs(runId, obj);
                await logArgumentModified(runId, originalArgs, obj, step);
                setStatus("Saved! ", step);
                setArgs(JSON.stringify(objNew, null, 4));
                setOriginalArgs({ ...obj });
            } else {
                setStatus("Invalid JSON");
            }
        } catch (error) {
            console.error("Error saving run args:", error);
            setStatus("Error saving run args: " + error);
        }

        setLoading(false);
    };

    const changeArgs = e => {
        try {
            setArgs(e.target.value);
        } catch (e) {
            //
        }
    };

    useEffect(() => {
        fetchRun(runId);
    }, [runId]);

    return (
        <div className="args-editor">
            <Checkbox checked={showHidden} onChange={e => setShowHidden(e.target.checked)}>Show hidden args</Checkbox>

            <textarea value={args} onChange={changeArgs} rows={48} />

            <div className="button-row">
                <Button loading={loading} onClick={() => fetchRun(runId)} type="default">Load</Button>
                <Button loading={loading} onClick={() => saveRun(runId, args)} type="primary">Save</Button>
            </div>

            {status}
        </div>
    );
}

export default ArgsEditor;