import { DeleteOutlined, RightOutlined } from "@ant-design/icons/es";
import { notification, Collapse, Checkbox } from "antd";
import { Button, Modal } from "antd";
import { memo, useEffect, useMemo, useState } from "react";
import { moveTrainingTopOfQueue, queueTraining } from "../api";
import { FILTERED_ARGS, DEFAULT_CODE, DEFAULT_ARGS, DATASET_ARGS, listDatasets, ARG_TYPE_MAP, OBSOLETE_ARGS, HIDDEN_ARGS } from "../datasets";
import { CheckOutlined } from "@ant-design/icons";
import { recursiveObjectSort, useEventListener } from "../util";


const TrainingSetup = memo(function TrainingSetup({ isOpen, setIsOpen, deriveArgs }) {
    const [args, setArgs] = useState(JSON.stringify(FILTERED_ARGS, null, 4));
    const [jsonError, setJsonError] = useState(null);
    const [topOfQueue, setTopOfQueue] = useState(true);
    const [bootFile, setBootFile] = useState("train-lora.py");
    const [code, setCode] = useState([...DEFAULT_CODE]);
    const [submitting, setSubmitting] = useState(false);
    const [dataset, setDataset] = useState({});

    const [datasets, setDatasets] = useState({});
    useEffect(() => {
        listDatasets().then(all => {
            const ds = {};

            for (const v of all) {
                ds[v.id] = v.parameters;
            }

            setDatasets(ds);
        });
    }, []);

    const deriveTraining = (deriveArgs) => {
        setArgs(old => {
            try {
                const combined = {
                    ...FILTERED_ARGS,
                    ...OBSOLETE_ARGS,
                    ...HIDDEN_ARGS,
                    ...(Object.fromEntries(Object.entries(deriveArgs)))
                };

                return JSON.stringify(recursiveObjectSort(combined), null, 4);
            } catch (e) {
                console.error(e);
                return JSON.stringify(recursiveObjectSort({ ...FILTERED_ARGS, deriveArgs }), null, 4);
            }
        });

        const dataset = Object.fromEntries(Object.entries(deriveArgs).filter(([k, v]) => Object.keys(DATASET_ARGS).includes(k)));
        setDataset(recursiveObjectSort(dataset));
    };

    useEventListener('derive-training', (args) => {
        console.log(args);
        deriveTraining(args);
        setIsOpen(true);
    });

    // useEffect(() => {
    //     const tag = `custom.derive-training`;

    //     const listener = (ev) => { deriveTraining(ev.detail); setIsOpen(true); }
    //     window.addEventListener(tag, listener);

    //     return () => window.removeEventListener(tag, listener);
    // }, []);

    useEffect(() => {
        if (deriveArgs) {
            deriveTraining(deriveArgs);
        }
    }, [deriveArgs]);

    const combinedArgs = useMemo(() => {
        try {
            return {
                ...DEFAULT_ARGS,
                ...JSON.parse(args),
                ...dataset,
            };
        } catch (e) { }
    }, [args, dataset]);

    const [notif, contextHolder] = notification.useNotification();

    const changeArgs = (e) => {
        setArgs(e.target.value);

        try {
            JSON.parse(e.target.value);
            setJsonError(null);
        } catch (e) {
            setJsonError("Invalid JSON: " + e.message);
        }
    };

    const reformat = () => {
        try {
            setArgs(JSON.stringify(JSON.parse(args), null, 4));
            setJsonError(null);
        } catch (e) {
            setJsonError("Invalid JSON: " + e.message);
        }
    };

    const revertArgs = () => {
        setArgs(JSON.stringify(FILTERED_ARGS, null, 4));
        setJsonError(null);
    };

    const beginTraining = (topOfQueue) => {
        const body = {
            args: combinedArgs,
            code,
            boot_file: bootFile
        };

        setSubmitting(true);

        queueTraining(body).then(job => {
            if (topOfQueue) {
                moveTrainingTopOfQueue(job.key);
            }

            setIsOpen(false);
            setSubmitting(false);

            notif.success({
                message: 'Training started',
                description: 'The training process has been queued and will start shortly.',
                btn: topOfQueue ? null : <TopOfQueueButton job={job} />
            });
        });
    };

    const close = () => {
        setIsOpen(false);
    };

    const items = [
        {
            key: 1,
            label: "Invocation",
            className: "collapse-body",
            children: <Invocation bootFile={bootFile} setBootFile={setBootFile} />
        },
        {
            key: 2,
            label: "Code bundle",
            className: "collapse-body",
            children: <CodeBundle code={code} setCode={setCode} />
        },
        {
            key: 3,
            label: "Dataset",
            className: "collapse-body",
            children: <DatasetEditor dataset={dataset} datasets={datasets} setDataset={setDataset} />
        },
        {
            key: 4,
            label: "Training Parameters",
            className: "collapse-body no-padding",
            children: <FreeformParameters args={args} changeArgs={changeArgs} reformat={reformat} />
        },
        {
            key: 5,
            label: "Combined Parameters",
            className: "collapse-body no-padding",
            children: <FreeformParameters readonly args={JSON.stringify(combinedArgs, null, 4)} changeArgs={() => { }} reformat={() => { }} />
        }
    ];

    return (
        <Modal title="Configure SDXL Training"
            okText={!jsonError ? (topOfQueue ? "Start Training" : "Schedule Training") : "Invalid JSON"}
            footer={(orig, _e) => <ExpandedFooter jsonError={jsonError} orig={orig} revertArgs={revertArgs} topOfQueue={[topOfQueue, setTopOfQueue]} />}
            okButtonProps={{ disabled: jsonError || submitting, icon: <RightOutlined /> }}
            width={800}
            open={isOpen}
            onOk={() => beginTraining(topOfQueue)}
            onCancel={close}
            className="training-modal"
        >
            {contextHolder}

            <div className="training-form args-editor">
                <Collapse
                    accordion
                    items={items}
                    defaultActiveKey={"1"}
                    size="small"
                />
            </div>
        </Modal>
    );
});

const TopOfQueueButton = (props) => {
    const { job } = props;

    const [clicked, setClicked] = useState(false);
    const [done, setDone] = useState(false);

    return (<>
        <Button size="small" onClick={() => { setClicked(true); moveTrainingTopOfQueue(job.key).then(() => setDone(true)) }} icon={done ? <CheckOutlined /> : null} loading={clicked && !done} disabled={done}>{(clicked || done) ? null : 'Top of queue'}</Button>
    </>)
}

const FreeformParameters = (props) => {
    const { args, changeArgs, reformat, readonly = false } = props;
    return (
        <textarea value={args} onChange={changeArgs} rows={32} onBlur={reformat} readOnly={readonly} />
    );
};

const DatasetEditor = (props) => {
    const { dataset, datasets, setDataset } = props;

    const modifyParameterAndCast = (key, value) => {
        const argType = ARG_TYPE_MAP[key];

        if (argType) {
            try {
                if (argType === 'int') {
                    const _value = Number.parseInt(value, 10);
                    // eslint-disable-next-line no-self-compare
                    if (_value !== _value) {
                        throw new Error("bad coder");
                    }

                    value = _value;
                } else if (argType === 'float') {
                    value = Number(value);
                } else if (argType === 'bool') {
                    if (value === 'true' || value === true) {
                        value = true;
                    } else if (value === 'false' || value === false) {
                        value = false;
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }

        setDataset(old => ({ ...old, [key]: value }));
    };

    const valueTypeErrors = Object.entries(dataset).filter(([key, value]) => {
        const argType = ARG_TYPE_MAP[key];
        if (!argType) {
            return false;
        }

        const t = typeof (value);

        if (argType === 'int' && (t !== 'number' || (value | 0) !== value)) {
            return true;
        } else if (argType === 'float' && t !== 'number') {
            return true;
        } else if (argType === 'bool' && t !== 'boolean') {
            return true;
        }

        return false;
    }).map(e => e[0]);

    return (<>
        <select defaultValue="" value="" onChange={e => setDataset(datasets[e.target.value])}>
            <option value="" disabled>Load dataset...</option>
            {Object.keys(datasets).map(d => <option key={d} value={d}>{d}</option>)}
        </select>

        <br />

        <div className="dataset-params compressed-table">
            <table>
                <thead>
                    <tr>
                        <th><b>Parameter</b></th>
                        <th><b>Value</b></th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {dataset && Object.entries(dataset).map(([k, v], i) => (
                        <tr key={k}>
                            <td style={{ fontSize: 13 }}>
                                {k}&nbsp;<span style={{ fontSize: 11, color: valueTypeErrors.includes(k) ? 'red' : 'deepskyblue', opacity: 0.75, fontFamily: "monospace", verticalAlign: 'super' }}>{ARG_TYPE_MAP[k]}</span>
                            </td>

                            <td className="code-url">
                                <input style={{ color: valueTypeErrors.includes(k) ? "red" : undefined }} value={v} onChange={e => modifyParameterAndCast(k, e.target.value)} />
                            </td>

                            <td>
                                <Button size="small" danger type="text" icon={<DeleteOutlined />} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </>)
}

const Invocation = (props) => {
    const { bootFile, setBootFile } = props;

    return (
        <div className="form-arg">
            <label>Boot file</label>
            <input value={bootFile} onChange={e => setBootFile(e.target.value)} />
        </div>
    )
}

const CodeBundle = (props) => {
    const { code, setCode } = props;

    return (<div className="code-bundle compressed-table">
        <table>
            <thead>
                <tr>
                    <th>Filename</th>
                    <th>URL</th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                {code.map((c, i) => (
                    <tr key={i}>
                        <td>
                            <input value={c.filename} onChange={e => {
                                const newCode = [...code];
                                newCode[i].filename = e.target.value;
                                setCode(newCode);
                            }} />
                        </td>

                        <td className="code-url">
                            <input value={c.url} style={{ margin: 0, flex: 1 }} onChange={e => {
                                const newCode = [...code];
                                newCode[i].url = e.target.value;
                                setCode(newCode);
                            }} />
                        </td>

                        <td>
                            <Button icon={<DeleteOutlined />} onClick={() => setCode(code.filter((_, j) => j !== i))} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>);
}

const ExpandedFooter = ({ jsonError, orig, revertArgs, ...props }) => {
    const [topOfQueue, setTopOfQueue] = props.topOfQueue;

    return (
        <div className="expanded-footer">
            {jsonError && <div className="json-error">{jsonError}</div>}

            <div className="expanded-footer--children">
                <Button type="link" onClick={revertArgs}>Revert</Button>
            </div>

            <Checkbox checked={topOfQueue} onChange={e => setTopOfQueue(e.target.checked)}>Up next</Checkbox>

            <div className="expanded-footer--orig">
                {orig}
            </div>
        </div>
    );
};

export { TrainingSetup };