import React, { useLayoutEffect } from "react";

import { Hoverable } from "./Hoverable";
import { CloseOutlined } from "@ant-design/icons";

const EMPTY_PIXEL = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

const TinyPreview = ({ url, remove, onClick, children, ...rest }) => {
    const imageRef = React.createRef();

    useLayoutEffect(() => {
        const img = imageRef.current;

        if (img) {
            img.style.width = (img.getBoundingClientRect().height + 'px');
        }
    }, [imageRef]);

    return (
        <div className="base-image-preview"
            ref={imageRef}
            onClick={onClick}
            style={{
                height: '100%',
                backgroundColor: '#0e1015',
                border: '1px solid #343d4a',
                padding: 2,
            }}
            {...rest}
        >
            <Hoverable component={url ? <img src={url ?? EMPTY_PIXEL} width={'100%'} style={{ maxWidth: 384, maxHeight: 384 }} /> : null}>
                <div style={{
                    height: '100%',
                    width: '100%',
                    backgroundImage: `url("${url ?? EMPTY_PIXEL}")`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}>
                    {children}

                    {remove && <div className="base-image-preview--close" onClick={remove} onMouseOverCapture={e => e.stopPropagation()}>
                        <CloseOutlined />
                    </div>}
                </div>
            </Hoverable>
        </div>
    )
};

export default TinyPreview;