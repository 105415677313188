import { useMemo, memo, useState, useEffect } from "react";
import { Badge, Col, Row, Tag } from "antd";
import CollapsingCard from "./CollapsingCard";
import { LockButton, loraPathComparator } from "./Inference";
import { ActiveLora } from "./lora/ActiveLora";
import { getLoraThumbnail, LoraCard } from "./LoraCard";
import { nsfw } from "../api";
import { deepEquals } from "../util";
import { CheckOutlined } from "@ant-design/icons";

function combineStyleCheckpoints(all) {
    // [path, timestamp, step, args, image_url]
    // timestamp: '2025-03-05/01h14m25s', step: 20500

    const dict = {};

    for (const model of all) {
        const { timestamp, step } = model;

        if (timestamp in dict) {
            dict[timestamp].push(model);
        } else {
            dict[timestamp] = [model];
        }
    }

    return dict;
}

export const LoraLibrary = memo(function LoraLibrary(props) {
    const { styleModels, activeLoras, setActiveLoras, locked, setLocked, singleLoraMode } = props;

    const [expandedGroup, setExpandedGroup] = useState(null);

    const sortedModels = useMemo(() => [...Object.values(styleModels)].sort(loraPathComparator), [styleModels]);
    const activeKeys = useMemo(() => activeLoras.map(m => m.path), [activeLoras]);
    const visibleModels = useMemo(() => nsfw ? [...sortedModels] : sortedModels.filter(m => !m.args.nsfw), [sortedModels]);
    const grouped = useMemo(() => combineStyleCheckpoints(sortedModels), [sortedModels]);

    const activeList = useMemo(() => activeLoras.map(m => (
        <ActiveLora
            key={m.path}
            m={m}
            styleModels={styleModels}
            setActiveLoras={setActiveLoras} />
    )), [activeLoras, styleModels, setActiveLoras]);

    const activeGroups = useMemo(() => new Set(activeLoras.map(l => l.path.substring(0, l.path.lastIndexOf('/')))), [activeLoras]);

    const activeTitle = <>
        <span style={{ fontSize: 12 }}>Active </span>
        {<Tag color={"gold"} size={"small"} style={{ marginLeft: 4, padding: '0 6px', fontSize: 11, color: '#e8b339' }}>{activeLoras.length}</Tag>}
        <LockButton {...{ locked, setLocked }} size="small" />
    </>

    return (<>
        <CollapsingCard title={activeTitle} size="small" bodyStyle={{ background: '#262a33', overflowY: 'auto', maxHeight: 143 }} style={{ background: '#0e1015', border: '1px solid #343d4a', marginBottom: 8 }}>
            <Row gutter={[16, 16]} align="middle" justify={"center"}>
                <Col span={24}>
                    {activeList.length ? activeList : 'No active styles.'}
                </Col>
            </Row>
        </CollapsingCard>

        <CollapsingCard title={<span style={{ fontSize: 12 }}>Available ({visibleModels.length}/{sortedModels.length})</span>} size="small" bodyStyle={{ background: '#262a33', minHeight: 0, overflowY: 'scroll', maxHeight: 400 }} style={{ background: '#0e1015', border: '1px solid #343d4a' }}>
            <Row gutter={[4, 4]} align="middle" justify={"center"}>
                {Object.entries(grouped).map(([group, models]) => (
                    <LoraGroup
                        key={group}
                        group={group}
                        models={models}
                        activeGroups={activeGroups}
                        activeKeys={activeKeys}
                        setActiveLoras={setActiveLoras}
                        singleLoraMode={singleLoraMode}
                        expanded={expandedGroup}
                        setExpanded={setExpandedGroup}
                    />
                ))}
            </Row>
        </CollapsingCard>
    </>);
}, deepEquals);

const LoraGroup = (props) => {
    const { group, models, activeGroups, activeKeys, setActiveLoras, singleLoraMode, expanded, setExpanded } = props;
    const { nsfw: isNsfw } = models[0].args;

    const anyActive = activeGroups.has(group);

    return (
        <>
            <Col span={6} className={expanded === group ? "lora-group-expanded" : undefined}>
                <div className={anyActive ? "lora-wrapper active" : "lora-wrapper"}>
                    <img src={getLoraThumbnail(models[0].image_url, isNsfw)} width="100%" draggable="false" className="lora-library-thumbnail" />
                    <div className="lora-hover-cover" onClick={() => setExpanded(old => old === group ? null : group)} />

                    <div className="lora-steps-counter">{models[0].step}</div>
                    <div className="lora-group-counter">{models.length}</div>

                    {anyActive && <div className="lora-deactivate-button">
                        {<CheckOutlined />}
                    </div>}
                </div>
            </Col>

            {expanded === group && <Col span={24} style={{ marginTop: -6 }}>
                <Row gutter={[4, 4]} align="middle" justify={"center"} className="lora-dikke-rukgroep">
                    {models.map(m => (
                        <LoraCard
                            key={m.path}
                            lora={m}
                            setActiveLoras={setActiveLoras}
                            enabled={activeKeys.includes(m.path)}
                            singleModel={singleLoraMode}
                        />
                    ))}
                </Row>
            </Col>
            }
        </>
    )
}

