import { memo } from "react";
import { Col } from "antd";
import { nsfw } from "../api";
import { CheckOutlined } from "@ant-design/icons/es";
import { defaultLoraScales } from "./Inference";
import { deepEquals } from "../util";

export function getLoraThumbnail(url, isNsfw = false) {
    let proxied = `https://training.bitgate.ai/cdn-cgi/image/format=webp,quality=90,fit=cover,width=96,height=96/${url}`;

    if (isNsfw && !nsfw) {
        proxied = `https://placehold.co/96x96/000000/ffffff?text=INVALID`;
    }

    return proxied;
}

export const LoraCard = memo((props) => {
    const { lora, setActiveLoras, enabled, singleModel } = props;

    const { path, args, image_url: imageUrl } = lora;
    const { nsfw: isNsfw } = args;

    // Toggles the inclusion of this card's LoRA.
    const toggle = () => {
        setActiveLoras(v => v.find(v => v.path === path)
            ? v.filter(x => x.path !== path)
            : [...v, { path, scales: defaultLoraScales(), disabled: false }]
        );
    };

    // Replaces the current LoRA(s) with this card's LoRA model.
    const replaceAll = () => {
        setActiveLoras(enabled ? [] : [{ path, scales: defaultLoraScales(), disabled: false }]);
    };


    return (
        <Col span={6}>
            <div className="lora-wrapper">
                <img src={getLoraThumbnail(imageUrl, isNsfw)} width="100%" draggable="false" className="lora-library-thumbnail" />
                <div className="lora-hover-cover" onClick={singleModel ? replaceAll : toggle} />

                <div className="lora-steps-counter">{lora.step}</div>

                {enabled && <div className="lora-deactivate-button">
                    <CheckOutlined />
                </div>}
            </div>
        </Col>
    );
}, deepEquals);
